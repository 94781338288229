import React from 'react'

import Layout from '../components/Layout'

const BoostPage = () => (
    <Layout bodyClass="blog">
        <iframe
            title="hs-blog"
            frameBorder="0"
            className="iframe-src"
            src="//blog.homespotter.com"
        />
    </Layout>
)

export default BoostPage
